body[data-theme="light"] {
    @import '../src/scss/light-slider.scss';

    .slider-tab {
        background-color: #D7CAAA;
    }

    .resume-button {
        border-color: 'black' !important;
        color: 'black';
        background: 'white';
    }
}